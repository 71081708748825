import logo from "./logo.jpg"

const  clientData = {
    client_entity: 85,
    attorney_firm: 'Coomber Consulting LLC',
    attorney_name: 'Skip Coomber',
    attorney_number: '760-205-3810',
    attorney_email: 'skip@coomber.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_coomber_consulting%2C_llc+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#2C3754',
    siteLink: 'http://www.frascognalaw.com/',
    logo
}

export default clientData